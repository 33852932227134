<template>
  <ui-component-modal
    :modalTitle="'Edit space'"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveSpace"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr>
            <td>
              <label>Name</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space name"
                v-model="mSpace.Name"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Number</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space number"
                v-model="mSpace.Number"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>External code</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space external code"
                v-model="mSpace.ExternalCode"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Width</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space width"
                v-model="mSpace.Width"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Length</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space length"
                v-model="mSpace.Length"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Height</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space number"
                v-model="mSpace.Height"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Area</label>
            </td>
            <td>
              <input
                class="input"
                type="text"
                placeholder="Space area"
                v-model="mSpace.M2"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import spaceProvider from '@/providers/space'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mSpace: null,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    saveSpace() {
      let self = this
      self.isSaving = true
      self.isSavingSuccess = false
      self.isSavingError = false

      spaceProvider.methods
        .updateSpace(self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.isSavingSuccess = true

            self.mSpace = response.data
            self.setSpace(self.mSpace)

            setTimeout(() => {
              self.$emit('spaceEdited', self.mSpace)
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((e) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
